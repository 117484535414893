
import {mapMutations} from 'vuex'
export default {
	props: {
		banks: {
			type: Array,
			default: []
		},
		offer: {
			type: Object,
			default: null
		}
	},
	methods: {
		...mapMutations({
			setCurrentCar: 'modal/modal-choose/SET_CURRENT_CAR'
		}),
		clickBank(slug){
			if(this.offer){
				this.setCurrentCar(this.offer)
			}
			this.$router.push(`/credit/${slug}`)
		}
	},
}
