
import { mapActions, mapGetters, mapMutations } from "vuex";
import offers from "@/apollo/queries/offer/offers";

export default {
	data() {
		return {
			showFilter: false,
			tabs: [

				{
					title: "До 500 000 ₽",
					slug: 'before-500'
				},
				{
					title: "От 500 000 ₽",
					slug: 'after-500'
				},
				{
					title: "От 1 000 000 ₽",
					slug: 'after-1000'
				},
				{
					title: "Автомат",
					slug: 'akpp'
				},
				{
					title: "Механика",
					slug: 'mkpp'
				},
				{
					title: "Последние поступления",
					slug: 'created-at'
				},
			],
			set: 'created-at-c',
			showButtonCompilation: true,
			checkFilterMobile: true,
		}
	},
	computed: {
		...mapGetters({
			offers: 'catalog/catalog-cars/offers',
			loading: 'catalog/catalog-cars/loading',
			catalog_route: 'catalog_route',
			showFilters: 'filters/filters/showFilters',
			flagShowFilters: 'filters/filters/flagShowFilters',
		}),
		offers_list() {
			return this.offers.data
		}
	},
	methods: {
		...mapActions({
			request: 'filters/filters/request',
			openModal: 'modal/modal-main/openModal',
		}),
		...mapMutations({
			setOffers: 'catalog/catalog-cars/SET_OFFERS',
			setLoading: 'catalog/catalog-cars/SET_LOADING',
			setShowFilters: 'filters/filters/SET_SHOW_FILTERS',
			setFlagShowFilters: 'filters/filters/SET_FLAG_SHOW_FILTERS',
		}),
		setShowFiltersHandler(){
				this.setShowFilters(!this.showFilters)
			},
		

		async filterRequest(assignVariables) {
			try {
				await this.setLoading(true)
				let response = await this.request({ query: offers, variables: assignVariables })

				await this.setLoading(false)
				this.setOffers(response.data.offers);

			} catch (error) {
				return this.$nuxt.error({ statusCode: 404, message: '404' })
			}
		},
		async getOffers() {
			await this.setLoading(true)
			let response = await this.request({
				query: offers,
				variables: {
					page: 1,
					limit: this.$device.isMobile == true ? 7 : 9,
					set: this.set,
					dateFormat: 'j F Y года.',
					sort: 'price|asc'
				}
			})
			this.setOffers(response.data.offers)
			await this.setLoading(false)
		},
		openFilter() {
			let payload = {
				modal_component: 'modal-filter-mobile',
				modal_data: 'filter-mobile',
				modal_title: 'Фильтр',
				// modal_sub_title: this.currentCarsCount
			}
			this.openModal(payload)
		},
		openMenu() {
			let payload = {
				modal_component: 'modal-filter-mobile',
				modal_data: 'filter-mobile',
				modal_title: 'Фильтр',
				modal_sub_title: ''
			}
			this.openModal(payload);
			this.$router.push('/cars');
		},
		tabClick(tab) {
			this.set = tab.slug
			this.getOffers()
		},
	},
	beforeMount() {
		if(this.$device.isMobile && this.flagShowFilters == false){
			 	this.setShowFilters(false)
				this.setFlagShowFilters(true)
		}
	},
	mounted() {
		this.getOffers()
	
	}
}
