
export default {
	props: {
		hasFancy: {
			type: Boolean,
			default: false
		},
		isForm: {
			type: Boolean,
			default: false
		},
		url: String,
		original: String,
		img: String,
	}
}
