
export default {
	props: {
		offers: Array
	},
	mounted() {
		new swiper.default('.swiper--catalog.swiper', {
			modules: [swiper.Navigation, swiper.Autoplay],
			loop: false,
			// autoplayDisableOnInteraction: true,
			autoplay: false,
			slidesPerView: 3,
			initialSlide: 0,
			// centeredSlides: true,
			watchSlidesProgress: true,
			spaceBetween: 24,
			navigation: {
				nextEl: '.catalog__list .swiper-button-next',
				prevEl: '.catalog__list .swiper-button-prev',
			},
		});
	},
}
