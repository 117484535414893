
export default {
    data() {
        return {
            collections: [
                {
                    slug: 'sedan',
                    url: '/cars/sedan',
                    title: 'Седаны'
                },
                {
                    slug: 'allroad',
                    url: '/cars/allroad',
                    title: 'Внедорожники'
                },
                {
                    slug: 'hatchback',
                    url: '/cars/hatchback',
                    title: 'Хэтчбеки'
                },
                {
                    slug: 'liftback',
                    url: '/cars/liftback',
                    title: 'Лифтбеки'
                },
                {
                    slug: 'universal',
                    url: '/cars/universal',
                    title: 'Универсалы'
                },
            ]
        }
    },
}
