import { render, staticRenderFns } from "./index.vue?vue&type=template&id=15d9f15f"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MakesUpper: require('/home/d/didisher2/autodrive.spb.ru/www/components/makes/makes-upper.vue').default,Makes: require('/home/d/didisher2/autodrive.spb.ru/www/components/makes/makes.vue').default,CatalogListIndex: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-list/catalog-list-index.vue').default,FeaturedBanks: require('/home/d/didisher2/autodrive.spb.ru/www/components/featured/featured-banks.vue').default,CatalogServices: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-services.vue').default,FeaturedCarsPopular: require('/home/d/didisher2/autodrive.spb.ru/www/components/featured/featured-cars-popular.vue').default,SeoTextHome: require('/home/d/didisher2/autodrive.spb.ru/www/components/seo-text/seo-text-home.vue').default})
