import { render, staticRenderFns } from "./catalog-list-index.vue?vue&type=template&id=9e8e0c1a"
import script from "./catalog-list-index.vue?vue&type=script&lang=js"
export * from "./catalog-list-index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeaturedAlternative: require('/home/d/didisher2/autodrive.spb.ru/www/components/featured/featured-alternative.vue').default,FilterDesktop: require('/home/d/didisher2/autodrive.spb.ru/www/components/filter/filter-desktop.vue').default,HeadingH2: require('/home/d/didisher2/autodrive.spb.ru/www/components/heading/heading-h2.vue').default,CatalogItemLargeMobile: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-item/catalog-item-large-mobile.vue').default,CatalogIndexSwiper: require('/home/d/didisher2/autodrive.spb.ru/www/components/catalog/catalog-index-swiper.vue').default,ButtonTypical: require('/home/d/didisher2/autodrive.spb.ru/www/components/button/button-typical.vue').default})
