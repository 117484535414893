import seoTags from "@/apollo/queries/seoTags";
import folders from "@/apollo/queries/folder/folders";
import generations from "@/apollo/queries/generations";
import {mapGetters} from "vuex";

export default {
    async asyncData({app, route, store, error}) {
        let client = app.apolloProvider.defaultClient

        let seo = await client.query({
            query: seoTags,
            variables: {site_id: store.getters.site_id, url: route.path === '/' ? '/home' : route.path}
        })
        // console.log(store.getters['marks/marks/arrSet'])
        if(route.params.mark){

            let arrSet = store.getters['marks/marks/arrSet'];
            if(arrSet.includes(route.params.mark)){
                store.commit('folders/folders/SET_FOLDERS', null)
            }else{
                let currentMark = store.getters['marks/marks/markFolderGeneration'].find(item => item.slug === route.params.mark)
                let folders;
                if(currentMark.folders){
                    folders = [...currentMark.folders];
                    folders.sort((a, b) => parseFloat(b.offers_count) - parseFloat(a.offers_count))
                }
                store.commit('folders/folders/SET_FOLDERS', folders)
            }
        }
        if (route.params.model) {
            let currentMark = store.getters['marks/marks/markFolderGeneration'].find(item => item.slug === route.params.mark)
			let folders;
            let generations;
            let generationsSort;
			if(currentMark.folders){
			    folders = [...currentMark.folders];
			    folders.sort((a, b) => parseFloat(b.offers_count) - parseFloat(a.offers_count))
			}
            if(folders){
                generations = folders.find(item => item.slug === route.params.model)
            }
            if(generations){
                generationsSort = [...generations.generations];
                generationsSort = generationsSort.sort((a, b) => parseFloat(b.offers_count) - parseFloat(a.offers_count))
            }
            // let response = await client.query(
            //     {
            //         query: generations,
            //         variables: {
            //             site_id: store.getters.site_id,
            //             mark_slug: route.params.mark,
            //             folder_slug: route.params.model
            //         }
            //     })
            store.commit('generations/generations/SET_GENERATIONS', generationsSort)
        }
        return {
            description: seo.data.seoTag.description,
            pageTitle: seo.data.seoTag.page_title,
            title: seo.data.seoTag.title,
            crumbs: seo.data.seoTag.crumbs
        }
    },
    computed: {
        ...mapGetters({
            domain: 'domain',
            offer: 'catalog/catalog-cars/offer',
        }),
    },
    head() {
        let currentMeta = [
            {
                hid: 'desctiption',
                name: 'description',
                content: `${this.description}`
            },
            {
                hid: 'og:type',
                property: 'og:type',
                content: 'website',
            },
            {
                hid: 'og:url',
                property: 'og:url',
                content: this.domain + this.$route.path,
            },
            {
                hid: 'og:title',
                property: 'og:title',
                content: `${this.title}`
            },
            {
                hid: 'og:description',
                property: 'og:description',
                content: `${this.description}`
            },
            {
                rel: 'canonical',
                href: this.domain + this.$route.path
            }

        ]
        let canonicalDomain = this.domain + this.$route.path

        if (this.domain !== 'https://autodrive.spb.ru') {
            currentMeta.push({
                name: 'robots',
                content: 'noindex, nofollow'
            })
        }

        if (this.offer) {
            currentMeta.push({
                hid: 'og:image',
                property: 'og:image',
                content: this.offer.images[0].thumb
            })
        } else {
            currentMeta.push({
                hid: 'og:image',
                property: 'og:image',
                content: this.domain + '/preview.png'
            })
        }

        return {
            title: `${this.title}`,
            link: [
                {
                    rel: 'canonical',
                    href: this.domain + this.$route.path
                }
            ],
            meta: currentMeta
        }
    }
}