
import {mapActions, mapGetters} from "vuex";

export default {
	props: {
		offer: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			banksFiltered: null,
			stateOpenBanks: true,
		}
	},
	computed: {
		...mapGetters({
			banks: 'banks/banks',
		}),
	},
	
	methods: {
		...mapActions({
			request: 'filters/filters/request',
		}),
		openBanks(){
			this.banksFiltered = this.banks
			this.stateOpenBanks = false
		}
	},
	async fetch() {
		if(this.$route.path != '/banks'){
			this.banksFiltered = this.banks.filter((month,idx) => idx < 5)
	   	}else{
			this.banksFiltered = this.banks
		}
	}
}
