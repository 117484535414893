
	import {mapGetters} from "vuex";
export default {
    computed: {
		...mapGetters({
			settings: 'settings/settings/settings'
		}),
        services(){
          return  [
                {
                    id: 6,
                    title: 'Кредит',
                    text: ' Расчитывайте кредит прямо на сайте',
                    link: '/credit'
                },
                {
                    id: 8,
                    title: 'Trade-In',
                    text: `Обменивайте свой автомобиль с выгодой ${this.settings.sale_tradein} ₽`,
                    link: '/exchange'
                },
                {
                    id: 1,
                    title: 'Выкуп',
                    text: 'Выгодно продавайте свой автомобиль с бесплатной оценкой',
                    link: '/buyout'
                },
                
            ]
        }
	},
}
